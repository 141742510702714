// strona listy produktów

import React, { FC, useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import qs from 'query-string';
import { Grid } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { Sliders, X } from 'react-bootstrap-icons';
import mapValues from 'lodash/mapValues';
import omitBy from 'lodash/omitBy';
import find from 'lodash/find';
import { ChevronLeft } from 'react-bootstrap-icons';

import { reduxActions, useDispatch, useSelector } from 'store';
import {
  useGetProducts,
  useGetProductsFiltersMain,
  useGetProductsSortMethods,
  useGetProductsTitle,
  useGetProductsBreadcrumbs,
  useGetCmsSectionArticle
} from 'api';
import { IProductsRequest, IProductsSortMethod } from 'api/types';
import { useAppNavigate, useRWD, useScrollDown, usePrevious } from 'hooks';
import { Categories, MainFilters } from './components';
import { MobileProductItem, ProductItem } from 'components/containers';
import { Breadcrumbs, Container, Loader, Select, Modal, Pagination } from 'components/controls';

import styles from 'theme/pages/Products/Products.module.scss';

const limits = [30, 60, 90, 120];

interface IFilter {
  filter_id: string;
  filter_value: string;
  filter_type?: 'singlechoice' | 'multichoice' | string;
}

// typ danych wejściowych
interface IProps {
  categoryId?: string;
  mode?: 'PROMOTIONS' | 'NEWS' | 'BESTSELLERS';
}

const Products: FC<IProps> = ({ categoryId, mode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useAppNavigate();
  const location = useLocation();

  const { category_id } = qs.parse(location.search);

  const getPosition = (string: string, subString: string, index: number) => {
    return string.split(subString, index).join(subString).length;
  };

  const urlPrefix = location.pathname.slice(0, getPosition(location.pathname, '/', 2));

  const { isMobile } = useRWD();

  const { isScrollDown } = useScrollDown();

  // globalnie ustawiona kategoria i fraza wyszukiwarki
  const { searchKeyword: globalSearchKeyword } = useSelector((state) => state.products);

  // kategoria pochodząca z url resolvera
  const urlResolverCategoryId = parseInt(categoryId || '0');

  const categoryIdQueryParam = urlResolverCategoryId
    ? { category_id: urlResolverCategoryId }
    : typeof category_id === 'string'
    ? { category_id: parseInt(category_id) }
    : {};

  // Aktualne filtry
  const [queryFilters, setQueryFilters] = useState<IFilter[]>([]);

  const [isFilterMobile, setIsFilterMobile] = useState(false);

  // poprzednia wartość kategorii
  const prevSearchKeyword = usePrevious(globalSearchKeyword);

  const initialPageFromUrl = qs.parse(location.search).page;

  // Parametry zapytania do API
  const [productsQuery, setProductsQuery] = useState<IProductsRequest>({
    limit: 30,
    search_keyword: '',
    filter_attributes: '',
    sort_method: '',
    mode: mode,
    ...categoryIdQueryParam,
    ...qs.parseUrl(window.location.href, { parseNumbers: true }).query,
    page: typeof initialPageFromUrl === 'string' ? parseInt(initialPageFromUrl) : 1
  });

  // pobranie listy filtrów
  const { data: mainFiltersData } = useGetProductsFiltersMain(
    {
      ...productsQuery,
      page: 1,
      limit: 999
    },
    { keepPreviousData: false }
  );

  // Pobranie listy produktów
  const {
    data: productsData,
    isFetching,
    isLoading
  } = useGetProducts(productsQuery, {
    keepPreviousData: true,
    onSuccess: () => {
      if (localStorage.getItem('PRODUCT_ID')) {
        localStorage.removeItem('PRODUCT_ID');
      }
    }
  });

  // Pobranie nagłówka
  const { data: productsTitleData, refetch: refetchProductsTitleData } = useGetProductsTitle(
    productsQuery,
    { enabled: false, keepPreviousData: true }
  );

  // Pobieranie breadcrumbs
  const { data: productsBreadcrumbsData, refetch: refetchProductsBreadcrumbsData } =
    useGetProductsBreadcrumbs(productsQuery, { enabled: false, keepPreviousData: true });

  // Pobranie opcji sortowania
  const { data: productsSortingMethodsData } = useGetProductsSortMethods({
    page: 1,
    limit: 999
  });

  // pobieranie danych tylko przy zmianie kategorii i frazy wyszukiwania
  useEffect(() => {
    refetchProductsTitleData();
    refetchProductsBreadcrumbsData();
  }, [
    productsQuery.category_id,
    productsQuery.search_keyword,
    productsQuery.mode,
    productsQuery.filter_attributes
  ]);

  // pobieranie opisów seo
  const { data: seoDescriptionData, refetch: refetchSeoDescription } = useGetCmsSectionArticle(
    'CATEGORY_DESCRIPTION',
    categoryIdQueryParam.category_id?.toString() || '',
    {
      enabled: false
    }
  );

  useEffect(() => {
    if (categoryIdQueryParam.category_id) {
      refetchSeoDescription();
    }
  }, [categoryIdQueryParam.category_id]);

  // Zmiana url'a przy zmianie parametrów zapytania do API
  useEffect(() => {
    const { limit, mode, category_id, ...restQuery } = productsQuery;

    const navigationUrl = categoryId
      ? `${location.pathname.replace(urlPrefix, '')}?${qs.stringify(restQuery, {
          skipEmptyString: true
        })}`
      : `${location.pathname.replace(urlPrefix, '')}?${qs.stringify(
          { ...restQuery, category_id },
          {
            skipEmptyString: true
          }
        )}`;

    navigate(navigationUrl, { replace: true });
  }, [productsQuery.page, productsQuery.filter_attributes, productsQuery.sort_method]);

  // zerowanie filtrów przy zmianie frazy wyszukiwania
  useEffect(() => {
    if (globalSearchKeyword !== prevSearchKeyword && globalSearchKeyword && prevSearchKeyword) {
      setProductsQuery({
        ...productsQuery,
        filter_attributes: ''
      });
    }
  }, [globalSearchKeyword, prevSearchKeyword]);

  // Ustawienie aktywnych filtrów z url'a (podczas wejścia na stronę) i następnie podczas wybierania nowych filtrów
  useEffect(() => {
    typeof productsQuery.category_id !== 'undefined' &&
      typeof productsQuery.search_keyword === 'string' &&
      dispatch(reduxActions.setSearchKeyword(productsQuery.search_keyword));
    setQueryFilters(
      productsQuery.filter_attributes
        ?.split('|')
        .filter((item) => item)
        .map((queryFilter) => {
          const queryFilterArray = queryFilter.split('=');
          return {
            filter_id: queryFilterArray[0],
            filter_value: queryFilterArray[1]
          };
        }) || []
    );
  }, [productsQuery, categoryIdQueryParam.category_id]);

  // Ustawienie breadcrumbs'ów (przy renderowaniu strony)
  useEffect(() => {
    dispatch(
      reduxActions.setBreadcrumbs(
        productsBreadcrumbsData
          ? productsBreadcrumbsData.items.map((item) => ({
              name: item.name,
              path: item.category_id
                ? `/products?category_id=${item.category_id}&search_keyword=`
                : undefined
            }))
          : []
      )
    );
  }, [productsBreadcrumbsData?.items]);

  // aktualizacja parametrów zapytania przy zmianie kategorii
  useEffect(() => {
    setProductsQuery((prevState) => ({
      ...prevState,
      mode: undefined,
      filter_attributes: '',
      category_id: categoryIdQueryParam.category_id
    }));
  }, [categoryIdQueryParam.category_id]);

  // aktualizacja parametrów zapytania przy zmianie frazy wyszukiwania
  useEffect(() => {
    setProductsQuery((prevState) => ({
      ...prevState,
      mode: undefined,
      filter_attributes: '',
      search_keyword: globalSearchKeyword
    }));
  }, [globalSearchKeyword]);

  const urlSearchKeyword = qs.parseUrl(location.search).query.search_keyword;
  // Uaktualnienie frazy wyszukwania (w globalnym stanie) po zmianie tej danej w url'u
  useEffect(() => {
    typeof urlSearchKeyword === 'string' &&
      dispatch(reduxActions.setSearchKeyword(urlSearchKeyword));
  }, [urlSearchKeyword]);

  // Uaktualnienie kategorii (w globalnym stanie) po zmianie tej danej w url'u
  useEffect(() => {
    if (mode) {
      setProductsQuery((prevState) => ({
        ...prevState,
        category_id: undefined,
        limit: 30,
        mode
      }));
    }
  }, [mode]);

  // Funkcja aktualizująa filtry (w stanie komponentu i zapytaniu do API)
  const updateQueryFilters = (filters: IFilter[], currentFilter?: string) => {
    setProductsQuery((prevState) => {
      return {
        ...prevState,
        filter_attributes: filters
          .map((filter) => {
            const filterAttributes = qs.parse(
              prevState.filter_attributes?.replaceAll('|', '&') || ''
            );
            if (filter.filter_type === 'multichoice' && filterAttributes[filter.filter_id]) {
              if (currentFilter !== filter.filter_id) {
                return `${filter.filter_id}=${filterAttributes[filter.filter_id]}`;
              }

              return `${filter.filter_id}=${filterAttributes[filter.filter_id]};${
                filter.filter_value
              }`;
            }

            return `${filter.filter_id}=${filter.filter_value}`;
          })
          .join('|'),
        page: 1
      };
    });
  };

  const clearFilters = (filter: string) => {
    if (!filter) {
      setProductsQuery((prevState) => {
        return {
          ...prevState,
          search_keyword: '',
          filter_attributes: '',
          sort_method: '',
          page: 1
        };
      });

      return;
    }

    const filterAttributes = qs.parse(productsQuery.filter_attributes?.replaceAll('|', '&') || '');
    const filters = mapValues(filterAttributes, (attribute: string) =>
      attribute.replace(`;${filter}`, '').replace(`${filter};`, '').replace(filter, '')
    );

    setProductsQuery((prevState) => {
      return {
        ...prevState,
        filter_attributes: qs
          .stringify(
            omitBy(filters, (o) => !o),
            { encode: false }
          )
          .replaceAll('&', '|'),
        page: 1
      };
    });
  };

  // funkcja skrolująca stronę do góry
  const scrollToTop = useCallback(
    () => document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'smooth' }),
    []
  );

  const renderSelectedFilter = (filter: IFilter) => {
    const selectedFilter = mainFiltersData?.items.find((o) => o.id === filter.filter_id);
    return (
      <div key={filter.filter_id}>
        <div className={styles.filterType}>{selectedFilter?.label}</div>
        {filter.filter_id === 'PRICE' ? (
          <div className={styles.selectedFilter}>
            <span>{filter.filter_value.replace(';', ' - ')}</span>
            <X onClick={() => clearFilters(filter.filter_value)} />
          </div>
        ) : (
          filter.filter_value.split(';').map((value) => (
            <div className={styles.selectedFilter} key={value}>
              <span>{find(selectedFilter?.values, { value })?.name}</span>
              <X onClick={() => clearFilters(value)} />
            </div>
          ))
        )}
      </div>
    );
  };

  const renderFiltersContent = () => (
    <div className={styles.filters}>
      {mainFiltersData?.items && !!queryFilters.length && (
        <div className={styles.selectedFilters}>
          <p className={styles.filterModalSelectedTitle}>
            <Trans>Aktywne filtry</Trans>
          </p>
          {queryFilters.map((filter) => renderSelectedFilter(filter))}
          <button className={styles.clearAllFilters} onClick={() => clearFilters('')}>
            <Trans>Wyczyść filtry</Trans>
          </button>
        </div>
      )}
      <Categories
        searchKeyword={globalSearchKeyword}
        onCategoryClick={(cat) => {
          navigate(
            `/products?category_id=${cat.id}&filter_attributes=${productsQuery.filter_attributes}`
          );
        }}
        chosenCategoryId={categoryIdQueryParam.category_id}
        productsQueryParams={productsQuery}
      />

      <MainFilters
        onChange={updateQueryFilters}
        queryFilters={queryFilters}
        filtersData={mainFiltersData?.items}
        clearFilters={clearFilters}
        categoryId={categoryIdQueryParam.category_id}
        searchKeywords={globalSearchKeyword}
      />
    </div>
  );

  const getActiveFiltersCount = (
    queryFilters: Array<{ filter_value: string; filter_id: string }>
  ) => {
    if (!queryFilters || queryFilters.length === 0) {
      return null;
    }

    const totalCount = queryFilters.reduce((sum, filter) => {
      const count = filter.filter_value.split(';').length;
      return sum + count;
    }, 0);

    return totalCount;
  };

  const ActiveFiltersCount = () => {
    const activeFilters = getActiveFiltersCount(queryFilters);

    if (!activeFilters) {
      return null;
    }

    return <div className={styles.filterBox}>{activeFilters}</div>;
  };

  return (
    <div className={classnames(styles.wrapperComponent, 'StylePath-Pages-Products')}>
      {productsTitleData && (
        <Helmet>
          <title>{productsTitleData.name} - Momenti Per Me</title>
          <link rel="canonical" href={window.location.href} />
        </Helmet>
      )}
      <Container>
        <div className={styles.breadcrumbsWrapper}>
          <Breadcrumbs />

          {!isMobile && (
            <Select<IProductsSortMethod>
              onChange={(sortMethod) =>
                sortMethod &&
                setProductsQuery({
                  ...productsQuery,
                  sort_method: sortMethod.id,
                  page: 1
                })
              }
              value={productsQuery.sort_method}
              options={
                productsSortingMethodsData?.items.map((item) => ({
                  value: item.id,
                  label: item.name,
                  item
                })) || []
              }
              placeholder={t('Sortuj')}
              disableDropdown
            />
          )}
        </div>
      </Container>
      {isFilterMobile ? (
        isMobile && (
          <Modal
            fullScreen={true}
            isFiltersOverlay={true}
            title={
              <div className={styles.filterModalTitle} onClick={() => setIsFilterMobile(false)}>
                <ChevronLeft />
                <Trans>Wróć</Trans>
              </div>
            }>
            <div className={classnames(styles.wrapperComponent, 'StylePath-Pages-Products')}>
              <div className={styles.content}>{renderFiltersContent()}</div>
            </div>
          </Modal>
        )
      ) : (
        <Container>
          {seoDescriptionData?.article_fields?.[0]?.value && (
            <h2
              className={styles.seoBlock}
              dangerouslySetInnerHTML={{
                __html: seoDescriptionData?.article_fields?.[0].value
              }}
            />
          )}
          <div className={styles.content}>
            {!isMobile && renderFiltersContent()}
            <div className={styles.list}>
              {isMobile && (
                <div
                  className={classnames(styles.bottomBar, {
                    [styles.isScrollDown]: !isScrollDown
                  })}>
                  <div className={styles.filterWrapper}>
                    <div onClick={() => setIsFilterMobile(true)} className={styles.filterButton}>
                      <Sliders />
                      <Trans>FILTRY</Trans>
                      <ActiveFiltersCount />
                    </div>
                    <div className={styles.sortingSelectWrapper}>
                      <Select<IProductsSortMethod>
                        onChange={(sortMethod) => {
                          sortMethod &&
                            setProductsQuery({
                              ...productsQuery,
                              sort_method: sortMethod.id,
                              page: 1
                            });
                        }}
                        value={productsQuery.sort_method}
                        options={
                          productsSortingMethodsData?.items.map((item) => ({
                            value: item.id,
                            label: item.name,
                            item
                          })) || []
                        }
                        placeholder={t('SORTUJ')}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.limitWrapper}>
                {!isLoading && (
                  <Select
                    onChange={(value) => {
                      value &&
                        setProductsQuery({
                          ...productsQuery,
                          limit: value.value,
                          page: 1
                        }),
                        scrollToTop();
                    }}
                    value={productsQuery.limit}
                    options={limits.map((limit) => ({
                      value: limit,
                      label: `${t('Pokaż')} ${limit}`,
                      item: { value: limit }
                    }))}
                    placeholder={t('Sortowanie')}
                  />
                )}
              </div>
              {isFetching && (
                <Grid item sm={12} className={styles.loadingWrapper}>
                  <Loader />
                </Grid>
              )}
              <Grid
                container
                columnSpacing="3px"
                rowSpacing="24px"
                itemScope
                itemType="http://schema.org/ItemList">
                {productsData?.items.map((product, i) => (
                  <Grid
                    className={`productCardWrapper-${product.page}`}
                    key={product.id}
                    item
                    xs={12}
                    sm={6}
                    lg={4}
                    itemProp="itemListElement"
                    itemScope
                    itemType="http://schema.org/ListItem">
                    {isMobile ? (
                      <MobileProductItem
                        product={product}
                        categoryId={categoryIdQueryParam.category_id}
                        searchKeywords={globalSearchKeyword}
                      />
                    ) : (
                      <ProductItem
                        product={product}
                        categoryId={categoryIdQueryParam.category_id}
                        searchKeywords={globalSearchKeyword}
                        mode={productsQuery.mode}
                      />
                    )}
                    <meta itemProp="position" content={String(i + 1)} />
                  </Grid>
                ))}
                <Pagination
                  pagesCount={productsData?.total_pages || 1}
                  page={productsQuery.page || 1}
                  onChange={(page) => {
                    scrollToTop();
                    setProductsQuery({ ...productsQuery, page });
                  }}
                />
                {seoDescriptionData?.article_fields?.[1]?.value && (
                  <h2
                    className={styles.seoBlock}
                    dangerouslySetInnerHTML={{
                      __html: seoDescriptionData?.article_fields?.[1].value
                    }}
                  />
                )}
              </Grid>
            </div>
          </div>
        </Container>
      )}
    </div>
  );
};

export default Products;
